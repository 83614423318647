@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


body {
  margin: 0;
  font-family:Montserrat,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;

}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {

  scrollbar-width: none;
  
}